'use strict';

module.exports = {
  selectCountry: function () {
    var countrySelect = $(".select2-country-switcher");

    if (countrySelect.length < 1) return;

    function matchStart(params, data) {
      params.term = params.term || '';
      if (data.text.toUpperCase().trim().startsWith(params.term.toUpperCase())) {
          return data;
      }
      return false;
    }

    function formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var $country = $(
        '<span class="country-label">' + state.text + '</span></span>'
      );
      return $country;
    };
    countrySelect.select2({
      templateResult: formatState,
      templateSelection: formatState,
      matcher: function(params, data) {
        return matchStart(params, data);
    }
    }).on('select2:open', () => {
      countrySelect.parent(".skp-select-country").addClass('select2-results');
      $('.select2-dropdown').parent().addClass('select2-country-switcher-dropdown');
      $('.select2-country-switcher-dropdown .select2-search').addClass('autofocus-search');
        document.querySelector('.autofocus-search .select2-search__field').focus();
    });

  }
};