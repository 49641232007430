'use strict';

var cart = require('../cart/cart');
const { getCookie } = require('../coockie');

var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.count-quantity').empty();
            $('.count-quantity').text('(' + count.quantityTotal + ')');
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });
    function ShowMinicart() {
        if ($('.search:visible').length === 0) {
            return;
        }
        
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                $('.skp-header').addClass('show-popup');
                initializeScrollbar();
                return;
            }
    
            $('.minicart .popover').addClass('show');
            $('.skp-header').addClass('show-popup');
            $('.minicart .popover').spinner().start();
    
            heightMiniCart();
            initializeScrollbar();
            
            var countQuantity = $('.minicart-total.hide-link-med .minicart-quantity').text();

            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                $('.count-quantity').empty();
                $('.count-quantity').text('(' + countQuantity + ')');
                updateMiniCart = false;

                heightMiniCart();
                initializeScrollbar();
                
                $.spinner().stop();
            });
        }
    }
    
    function initializeScrollbar() {
        var $scrollContainer = $(".custom-scroll-mini-cart");
        if ($scrollContainer.length > 0) {
            if (!$scrollContainer.hasClass('mCustomScrollbar')) {
                $scrollContainer.addClass('customize-scroll-miniCart').mCustomScrollbar({
                    theme: "dark",
                    scrollInertia: 100,
                    mouseWheel: {
                        deltaFactor: 50
                    }
                });
            } else {
                $scrollContainer.mCustomScrollbar('update');
            }
        }
    }


    function heightMiniCart() {
        var heightCountrySwitcher = $('.country-switcher-header').outerHeight() || 0;
        var heightHeader = $('header').outerHeight() || 0;
        var cardBannerbox = $('.card-bannerbox').outerHeight() || 0;
        var cardHeader = $('.skp-cart-header').outerHeight() || 0;
        var minicartFooter = $('.minicart-footer').outerHeight() || 0;
    
        var totalHeight = heightHeader + cardBannerbox + cardHeader + minicartFooter;
        
        if (getCookie('SKP_ShowBanner') === 'true') {
            totalHeight += heightCountrySwitcher;
        }
    
        var availableHeight = window.innerHeight - totalHeight;
    
        var maxHeight = Math.min(availableHeight, 260);
    
        $('.skp-popover-mini .product-summary').css('max-height', maxHeight + 'px');
    }
    
    $(document).ready(function() {
        heightMiniCart();
    
        $(window).on('resize', function() {
            heightMiniCart();
        });
    });
    
    $('.minicart').on('mouseenter focusin touchstart', function () {
        ShowMinicart();
    });

    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
            $('.skp-header').removeClass('show-popup');
        }
    });
    $('.minicart').on('click','.skp-cart-header .close', function (event)  {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
        $('.skp-header').removeClass('show-popup');
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
        $('.skp-header').removeClass('show-popup');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
        ShowMinicart();
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
