'use strict';
var baseClientSideValidation = require('base/components/clientSideValidation');
var scrollAnimate = require('base/components/scrollAnimate');

function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
}

baseClientSideValidation.invalid = function () {
        
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                if($(this).attr('id') == "dwfrm_profile_civility_1"){
                    $(this).closest(".form-control").addClass('is-invalid');
                }else{
                    $(this).addClass('is-invalid');
                }
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);

                    var billingAdress = $('.billing-address');
                    if(billingAdress.lenght>0){
                        $('.skp-billing-select').addClass('d-none');
                        billingAdress.addClass('d-block').removeClass('d-none');
            
                        $('html, body').stop().animate({
                            scrollTop: billingAdress.offset().top
                        }, 1500);
                    }
                  
            }
        });
    }

baseClientSideValidation.buttonClick = function () {
    $('.checkout-shipping-address-form button[type="submit"], .checkout-shipping-address-form input[type="submit"]').on('click', function () {
        // clear all errors when trying to submit the form
        clearForm($(this).parents('form'));
        // force the shipping form validation client side
        $('.checkout-shipping-address-form').trigger('submit');
    });
}

function showErrorMessageGender(radioInput) {
    var feedbackElement = radioInput.parents('.skp-status').children('.invalid-feedback-address');
    if (radioInput.prop('checked')) {
        var errorMessage = radioInput.data('invalid-message');
        feedbackElement.empty().append(errorMessage);
    } else {
        feedbackElement.empty();
    }  
}

$('button.submit-payment').on('click', function () {
    showErrorMessageGender($('.billing-address input[type="radio"][id$="_0"]'));
    // clear all errors when trying to submit the form
    clearForm($('.checkout-billing-address-form'));
    // force the billing form validation client side
    var isBillingFormValid = true;
    $($('.checkout-billing-address-form')).find('input, select').each(function () {

        if (!this.validity.valid) {
            isBillingFormValid = false;
            $(this).next('.invalid-feedback-billing').empty().append(Resources.LABEL_ADRESSE_BILLING_ERROR_MESSAGE);
            $(this).closest('.select2-custom').find('.invalid-feedback-billing').empty().append(Resources.LABEL_ADRESSE_BILLING_ERROR_MESSAGE);
            $(this).closest('.skp-state').find('.invalid-feedback-billing').empty().append(Resources.LABEL_ADRESSE_BILLING_ERROR_MESSAGE);
            $(this).parent('.skp-phone-input').next('.invalid-feedback-billing').empty().append(Resources.LABEL_ADRESSE_BILLING_ERROR_MESSAGE);
            $(this).parents('.skp-phone-form').find('.invalid-feedback-phoneNumber-billing').addClass('red-message');
            $(".select-phone-regex").trigger('change');
            
        }
    });
    if (!isBillingFormValid) {
        $('.checkout-billing-address-form').trigger('submit'); // reforce the form validation
    } //else continue the usual flow
});

$('.btn-address-confirmation').on('click', function () {
    showErrorMessageGender($('input[type="radio"][id$="_0"]'));
});
    module.exports = baseClientSideValidation ;
