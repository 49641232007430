// Slick slider-banner for header-banner
$('.slider-banner')
.slick({
    speed:1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    centerMode: false,
    focusOnSelect: false,
    autoplay:true
});