'use strict';
module.exports = {
    updateQuantity: function () {
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);
        var valueQuantity = $('.minicart-total.hide-link-med .minicart-quantity');

        if (count !== 0) {
            valueQuantity.removeClass('skp-no-product');
        } else {
            valueQuantity.addClass('skp-no-product');
        }
    }
};