'use strict';

module.exports = {
    pushToGtm: function (viewData) {
        var isGtmLoaded = false;
        function checkGtmLoaded(viewData) {
            setTimeout(function() {
                if (dataLayer.filter(function(e) { return "gtm.load" === e.event}).length > 0 && !isGtmLoaded) {
                    dataLayer.push(viewData);
                    isGtmLoaded = true;
                } else {
                    checkGtmLoaded(viewData);
                }
            }, 500)
        }
        checkGtmLoaded(viewData);
    }
}
