window.productsCrossDataManager = {
   
    add: function(productInfos){
        if(productInfos.event == "view_item_list"){
            return;
        }
        var viewedListItems =   JSON.parse(localStorage.getItem("view_gtm_products")) || [];
        var itemArrayIndex = viewedListItems.findIndex((el) => el.item_child_sku === productInfos.item_child_sku);
        //add item if it absent and replace if present
        productInfos.at =new Date().getTime();
        if (itemArrayIndex === -1) {
            viewedListItems.push(productInfos);
        } else {
            viewedListItems[itemArrayIndex] = productInfos;
        }
        viewedListItems = this.checkExpiration(viewedListItems);
        localStorage.setItem("view_gtm_products", JSON.stringify(viewedListItems));
    },
    updateItemByCrossInfos: function(itemsData){
        if (itemsData.ecommerce && itemsData.ecommerce.items) {
            itemsData.ecommerce.items.map(item => {
                item.item_category && (item.item_category = item.item_category.replace('&amp;', '&'));
                item.item_category2 && (item.item_category2 = item.item_category2.replace('&amp;', '&'));
                item.item_category3 && (item.item_category3 = item.item_category3.replace('&amp;', '&'));
                item.item_list_name && (item.item_list_name = item.item_list_name.replace('&amp;', '&'));
            });
        }
        if(itemsData.event == "view_item_list"){
            return;
        }
        var crossProductsData =   JSON.parse(localStorage.getItem("view_gtm_products")) || [];
        if(crossProductsData.length==0){
            return itemsData;
        }
        
        if(itemsData.ecommerce && itemsData.ecommerce.items){
            for(var i = 0; i<itemsData.ecommerce.items.length; i++){
                var crossProduct = Object.keys(crossProductsData)
                .filter(item => crossProductsData[item].item_id ===itemsData.ecommerce.items[i].item_id)
                .map(item => crossProductsData[item]);
                if (crossProduct.length === 1) {
                    let listName = crossProduct[0].item_list_name;
                    itemsData.ecommerce.items[i].index = crossProduct[0].index;
                    itemsData.ecommerce.items[i].item_list_name = listName ? listName.replace('&amp;', '&'): "";
                    itemsData.ecommerce.items[i].item_list_id = crossProduct[0].item_list_id;
                }
            }
        }
        return itemsData;
    },
    clear: function(){
        localStorage.removeItem('view_gtm_products');
    },
    checkExpiration: function(items){
        for(var i = 0; i<items.length; i++){
            var fromTime = items[i].at ;
            var toTime = new Date();
            var differenceTravel = toTime.getTime() - fromTime;
            var seconds = Math.floor((differenceTravel) / (1000));
            if (seconds > 259200 ){ // = 30 days
                items.splice(i, 1);
            } 
        }
        return items;
    }
}