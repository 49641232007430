'use strict';

const { getCookie } = require('../coockie');

var keyboardAccessibility = require('base/components/keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');



    function setCookie(cName, cValue, expDays) {
        let date = new Date();
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    $('.country-switcher-header .close').on('click', function () {
        var hauteur_fenetre = $('header').outerHeight();
        $('.country-switcher-header').addClass('hide-country-switcher-header');
        $(".skp-header").css('top', '0px');
        if($('.skp-header').hasClass('fixed')){
            $("body").css('padding-top', hauteur_fenetre);
        }else{
            $("body").css('padding-top', '0px');
        }
        setCookie('SKP_ShowBanner', false, 300);
        setCookie('SKP_BannerActed', true, 300);
        setCookie('SKP_PrevShippingCountry', "", 300);
    });

    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
        var countrySwitcherHeight = $('.country-switcher-header').outerHeight();
        var hauteur_fenetre = $('header').outerHeight();
        if($('.country-switcher-header').hasClass('hide-country-switcher-header')){
            $('.skp-page').css({ 'margin-top': hauteur_fenetre  + 'px' });
        }else{
            $('.skp-page').css({ 'margin-top': hauteur_fenetre + countrySwitcherHeight + 'px' });
        }
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                        .first()
                        .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.nav-item:not(.disabled) .nav-menu-first[data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent('.nav-item').addClass('show');
                $('.menu-first-block').addClass('move-to-left');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu.skp-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
          .parent()
          .on('mouseleave', function () {
              if (isDesktop(this)) {
                 $(this).removeClass('show');
                  $(this).children('.dropdown-menu').removeClass('show');
                  $(this).children('.nav-link').attr('aria-expanded', 'false');
              }
          });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();

        $('.navbar-toggler').focus();

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav').find('.top-category').detach();
        $('.navbar-nav').find('.nav-menu').detach();
        $('.navbar-nav').find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
    });

    $(document).on('click', '.close-menu-nav', function(e) {
        $('.nav-item').removeClass('show');
        $('.menu-first-block').removeClass('move-to-left');
        $('ul.dropdown-menu.skp-menu').css({'top':0 + 'px'});
    });
    $('.skp-mobile-search').on('click', function () {
        $('.main-menu').removeClass('in');
        var searchFieldHeight = $('.search-field').outerHeight();
        var height_menu = $('.main-menu').outerHeight();
        var height_header = height_menu + searchFieldHeight;
        var negative_height_menu = -height_header;
        $('nav').css({ 'top': 0 + 'px' });
        $('.main-menu').css('top', negative_height_menu + 'px');
        $('.dropdown').removeClass('show');
        $('body').removeClass('menu-open');
        $('.block-page').addClass('of-hidden').removeClass('of-no-hidden');
        $('.navbar-toggler').removeClass('show-menu');
        $('.menu-toggle').removeClass('show-menu');
        $('.site-search').slideToggle();
        setTimeout(function() {
            $('.menu-first-block').removeClass('move-to-left');
         }, 550);
    });

    $(document).on('click', '.navbar-toggler', function (e) {
        // e.stopPropagation();
        e.preventDefault();

        var hauteur_fenetre = $('.header').outerHeight();
        var searchFieldHeight = $('.search-field').outerHeight();
        var height_menu = $('.main-menu').outerHeight();
        var height_header = height_menu + searchFieldHeight;
        var negative_height_menu = -height_header;
        var height_window = $(window).outerHeight();
        var height_of_menu = height_window - hauteur_fenetre;

        $('.main-menu').toggleClass('in');
        $('.menu-toggle').toggleClass('show-menu');
        if ($('.main-menu').hasClass('in')) {
            var headerBanner = $('.header-banner').outerHeight();
            var headerBannerHeight = -headerBanner;
            var countrySwitcherHeight = $('.country-switcher-header').outerHeight();
            $('.navig').css({ 'top': headerBannerHeight + 'px' });
            if (getCookie('SKP_ShowBanner') == 'true') {
                $('.main-menu').css({'padding-top': hauteur_fenetre + countrySwitcherHeight + 'px','top':0 + 'px'});
                $('ul.dropdown-menu.skp-menu').css({'padding-top': hauteur_fenetre + countrySwitcherHeight + 'px','top':0 + 'px'});
            }else{
                $('.main-menu').css({'padding-top': hauteur_fenetre + 'px','top':0 + 'px'});
                $('ul.dropdown-menu.skp-menu').css({'padding-top': hauteur_fenetre + 'px','top':0 + 'px'});
            }
            
            $('.site-search').slideUp();
            $('body').addClass('menu-open');
            $('.block-page').removeClass('of-hidden').addClass('of-no-hidden');
            if (getCookie('SKP_ShowBanner') == 'true') {
                $('.menu-first-block').css({'max-height':height_of_menu - countrySwitcherHeight,'overflow-y':'auto'});
            }else{
                $('.menu-first-block').css({'max-height':height_of_menu,'overflow-y':'auto'});
            }
            $('#FreeShippingBanner').css('display','none');
        } else {
            $('.navig').css({ 'top': 0 + 'px' });
            $('.main-menu').css('top', negative_height_menu + 'px');
            $('.dropdown').removeClass('show');
            $('body').removeClass('menu-open');
            $('.block-page').addClass('of-hidden').removeClass('of-no-hidden');
            $('#FreeShippingBanner').css('display','block');
        }
        $(this).toggleClass('show-menu');
        $('.modal-background').show();
        setTimeout(function() {
            $('.menu-first-block').removeClass('move-to-left');
         }, 550);
        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');
        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });
    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });

    $('.site-search .reset-button').on('click', function () {
		$('.search-field').focus();
    });
	$('.skp-button-search').on('click', function(){
		$('.search-field').focus();
	});
};
$('.navbar-nav > li').children('ul.dropdown-menu').addClass('skp-menu');
$('.dropdown-item').children('ul.dropdown-menu').addClass('skp-menu2');

$('.dropdown-menu.skp-menu2').removeClass('show');

$('.dropdown-menu.skp-menu,.skp-menu-push').children('li').addClass('skp-menu-padding col-md-2 col-12');

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header').outerHeight();

$(window).on('scroll', function(event) {
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var menuInterne = $('.skp-header-interne');
    var menuHeight = menuInterne.outerHeight();
    var countrySwitcherHeight = $('.country-switcher-header').outerHeight();
    var st = $(window).scrollTop();
    
    if (Math.abs(lastScrollTop - st) <= delta) {
        return;
    }

    if (st > lastScrollTop && st > navbarHeight) {
        $('header').removeClass('nav-down').addClass('nav-up');
        $('.skp-popover-mini').removeClass('cart-down').addClass('cart-up');
    } else {
        $('header').removeClass('nav-up').addClass('nav-down');
        if (st + $(window).height() < $(document).height()) {
            $('.skp-popover-mini').removeClass('cart-up').addClass('cart-down');
        }
    }

    if (st > lastScrollTop) {
        menuInterne.removeClass('fixed');
        if (getCookie('SKP_ShowBanner') == 'true') {
            $('body').css('padding-top', countrySwitcherHeight);
            $('.skp-header').css('top', '0px');
        } else {
            $('body').css('padding-top', '0px');
        }
    } else {
        menuInterne.addClass('fixed');
        if (getCookie('SKP_ShowBanner') == 'true') {
            $('body').css('padding-top', menuHeight + countrySwitcherHeight);
            $('.skp-header').css('top', countrySwitcherHeight);
        } else {
            $('body').css('padding-top', menuHeight);
        }
    }

    lastScrollTop = st;
}

var valueQuantity=$('.minicart-total.hide-link-med .minicart-quantity');
var valueQuantitytext=$('.minicart-total.hide-link-med .minicart-quantity').text().replace(/ /g,'');
if (valueQuantitytext === "0" ){
    valueQuantity.addClass("skp-no-product");
}
$(window).on( "load", function() {
    var valueQuantitytext=$('.minicart-total.hide-link-med .minicart-quantity').text().replace(/ /g,'');
    if (valueQuantitytext === "0" ){
        valueQuantity.addClass("skp-no-product");
    } else {
        valueQuantity.removeClass("skp-no-product");
    }
});


$(".accordion-title").click(function(e){
        var accordionitem = $(this).attr("data-tab");
        $("#"+accordionitem).slideToggle().parent().siblings().find(".accordion-content").slideUp();
        $(this).toggleClass("tec-arrow");
        $("#"+accordionitem).parent().siblings().find(".accordion-title").removeClass("tec-arrow");
});

$(".suggestions.skp-suggestions").css( 'top', 0 );

var windowWidth= $(window).width();

$('.dropdown-item-menu').click(function() {
    if (windowWidth < 838 ) {
        $(this).css('background-color', '#f8f9fa');
    }else{
        $(this).css('background-color', 'white');
    }
});

$('.skp-toggle').click(function() {
    if (windowWidth < 838 ) {
        $(this).css('background-color', '#f8f9fa');
    }else{
        $(this).css('background-color', 'white');
    }
});

$(document).ready(function () {
    $('.country-switcher-header .select2-country-switcher').next('.select2-container').addClass('CountrySwitcherSelect2');
    $('.country-switcher-header .country-switcher-locale').next('.select2-container').addClass('CountrySwitcherCodeSelect2');
    if ($('.navbar-header .user').hasClass('user-connected')) {
        $('.order-history-link').addClass('mode-connected');
        $('.skp-breadcrumb').addClass('mode-connected');
        $('.bloc-ctd-detail-cmd').addClass('mode-connected');
    }
});