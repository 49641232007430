'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var isLanguageDropDownShown = false;
const { getCookie } = require('../coockie');
/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button, form) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if (form.find('.email-signup-message').length === 0) {
        form.closest('.form-input').append(
            '<div class="email-signup-message"></div>'
        );
    }

    form.find('.email-signup-message')
        .html('<div class="email-signup-invalid ' + status + '">' + data.msg + '</div>');
    setTimeout(function () {
        form.find('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 5000);
}



var send = function (form,token) {
        var url = form.data('href');
        var button = form.find('button.subscribe-email');
        var formInput = form.find('.form-input');
        var emailId = $('input[name=hpEmailSignUp]').val();
        var civility = $('input[name=dwfrm_newsletter_civility]:checked').val();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                civility : civility,
                reCaptchatoken : token,
            },
            success: function (data) {
                $('body').trigger('subscribe:success', civility);
                displayMessage(data, button,formInput);
            },
            error: function (err) {
                displayMessage(err, button,formInput);
            }
        });
};
module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });
    $('form.newsletterForm').submit(function (e) {
        var form = $(this);
        e.preventDefault();
       if (window.sitePreferences.RECAPTCHA_V3_ENABLED) {
        grecaptcha.ready(function () {
            grecaptcha.execute(sitePreferences.RECAPTCHA_SITE_KEY, {
                action: 'submit'
            }).then(function (token) {
                send(form, token);

            });
        });
       }else{
            send(form, "");
       }
    })
};
$('.js-custom-select').select2({
    minimumResultsForSearch: -1,
});

$('.js-custom-select-change').select2({
    minimumResultsForSearch: -1,
});

$('.js-custom-select-country-switcher').select2({
    minimumResultsForSearch: -1,
}).on('select2:open', () => {
    $(".select2-container--default").addClass('select2-country-switcher');
    $('.select2-dropdown').parent().addClass('select2-country-switcher-dropdown');
});
$('.js-custom-select-order').select2({
    minimumResultsForSearch: -1,
}).on('select2:open', () => {
    $(".select2-container--default").addClass('select2-sort-order');
});

$('.js-custom-select-country').select2({
    minimumResultsForSearch: -1,
}).on('select2:open', () => {
    $(".select2-container--default").addClass('select2-popin');
});

$(".country-selector-show-popin").on('click', function () {

    if (typeof GlobalE !== 'undefined') {
        GlobalE.ShippingSwitcher.Show();
    } else {
        console.log("GlobalE not loaded !");
    }
})



function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

function makeJsonAjaxCall(url, type, data) {
    var promise = new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(type, url, true);
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.send(data || null);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(xhr.responseText);
                } else {
                    reject(xhr.status);
                }
            }
        };
    });
    return promise;
}

if (getCookie('SKP_ShowBanner') == 'true') {
    var countrySwitcherHeight = $('.country-switcher-header').outerHeight();
    $('.country-switcher-header').removeClass('hide-country-switcher-header');
    $('body').css('padding-top', countrySwitcherHeight);
} else {
    var countrySwitcherHeight = $('.country-switcher-header').outerHeight();
    $('.country-switcher-header').addClass('hide-country-switcher-header');
    $('body').css('padding-top', 0);
}

if (getCookie('SKP_FixedBannerDisplay') == 'true') {
    $('body').addClass('fixed-banner-cs');
    $('.country-switcher_overlay').addClass('show');
    $('.close-button').addClass('d-none');
    $('.country-switcher-header').addClass('change-padding');
} else {
    $('body').removeClass('fixed-banner-cs');
    $('.country-switcher_overlay').removeClass('show');
    $('.close-button').removeClass('d-none');
    $('.country-switcher-header').removeClass('change-padding');
}

$('.country-switcher-code').on('change', function () {
    var locales = $(this).find('option:selected').data('locales');
    var country = $(this).find('option:selected').val();
    $('.country-switcher-locale').empty();
    $.each(locales, function (index, locale) {
        $('.country-switcher-locale').append(`<option value=${locale.localeID}_${country}>${locale.localeLabel}</option>`)
    })
    $('.country-switcher-locale').trigger('change');
    if (locales && locales.length > 1) {
        $('.country-switcher-locale').next('.select2-container').show();
        isLanguageDropDownShown = true
    } else {
        $('.country-switcher-locale').next('.select2-container').hide();
        isLanguageDropDownShown = false;
    }
    $('.error-message-country-switcher').addClass('d-none');
});

$('.country-switcher-code').trigger('change');

$('.country-switcher-validation a').on('click', function () {
    if (typeof GlobalE !== 'undefined') {
        let country = $('.country-switcher-code').find('option:selected').val();
        let currency = $('.country-switcher-code').find('option:selected').data('currency');
        let locale = $('.country-switcher-locale').find('option:selected').val();

        GlobalE.UpdateCustomerInfo(country, currency, false);
        if (window.geScriptLoaderData.performFrontendSiteUrlRedirect) {
            var getSiteRedirectUrl = window.geScriptLoaderData.getSiteRedirectUrl;
            getSiteRedirectUrl += '?countryCode=' + encodeURIComponent(country);
            getSiteRedirectUrl += '&currencyCode=' + encodeURIComponent(currency);
            if (isLanguageDropDownShown && locale) {
                getSiteRedirectUrl += '&localeCode=' + encodeURIComponent(locale);
            }
            var requestDataEl = document.querySelector('[data-action][data-querystring]');
            if (requestDataEl) {
                getSiteRedirectUrl += '&action=' + encodeURIComponent(requestDataEl.dataset.action);
                getSiteRedirectUrl += '&querystring=' + encodeURIComponent(requestDataEl.dataset.querystring);
            }
            makeJsonAjaxCall(getSiteRedirectUrl, 'GET')
                .then(function (response) {
                    if (response) {
                        response = JSON.parse(response);
                        GlobalE.UpdateCustomerInfo(country, currency, false);
                        if (!response.success) {
                            var selectedCountry = $('.country-switcher-code').find('option:selected');
                            if (selectedCountry.is(':not(:disabled)')) {
                                $('.error-message-country-switcher').addClass('d-none');
                            } else {
                                $('.error-message-country-switcher').removeClass('d-none');
                            }
                        } else if (response.redirectUrl) {
                            window.location.href = response.redirectUrl;
                            setCookie('SKP_ShowBanner', false, 300);
                            setCookie('SKP_BannerActed', true, 300);
                            setCookie('SKP_PrevShippingCountry', "", 300);
                        }
                    }
                })
                .catch(function (e) {
                    console.warn(e); // eslint-disable-line no-console
                });
        }
    } else {
        console.log("GlobalE not loaded !");
    }
});


function applyScrollChange(){
    var wdithofwindow = $(window).width();

    if(wdithofwindow > 840){
        $(".custom-scroll").addClass('customize-scroll').mCustomScrollbar({
            theme: "dark",
            scrollInertia: 100,
                mouseWheel: {
                    deltaFactor: 50
                },
                callbacks: {
                    onScrollStart: function() {
                        $('.js-custom-select-change').select2('close');
                    },
                    onScroll: function() {
                        $('.js-custom-select-change').select2('close');
                    }
                }
                
        });
    }
}

applyScrollChange();

$(window).on('resize', function(){
    applyScrollChange()
});

$('.second-block-step-2 .block-product-to-change').on('scroll touchmove', function() {
    $('.js-custom-select-change').select2('close');
});