'use strict';

const utils = require("./utils");
const { getCookie } = require('../../coockie');
/*
* Track sign up
*/
$(document).on('login:register:success', 'form.registration', () => {
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'sign_up',
        'event_action': 'Click',
        'event_label': 'OK'
    };

    if (window.dataLayer) {
        window.dataLayer.push(data);
    }

});

$(document).on('login:register:error', 'form.registration', () => {
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'sign_up',
        'event_action': 'Click',
        'event_label': 'KO'
    };

    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
});

/*
 * Track menu navigation
 */
$(document).on('click', '.skp-nav a', (e) => {
    const categoryName = e.target.innerText;
 
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'menu_navigation',
        'event_action': 'Click',
        'event_label': categoryName
    };
    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
});

/*
* Track Subscriptions
*/
$(document).on('subscribe:success', 'body', (e, civility) => {
    const civilityNames = {
        1: 'Women',
        2: 'Men',
        3: 'Neutral'
    };
0
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'newsletter_suscription',
        'event_action': 'Click',
        'event_label': civilityNames[civility]
    };
    if (window.dataLayer) {
        window.dataLayer.push(data);

        if (window.dataLayer[0].user_data.user_subscribed === 'No') {
            window.dataLayer[0].user_data.user_subscribed = 'Yes';
        }
    }
});

/*
 * Track login in
 */
$(document).on('login:success', 'form.login', () => {
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'login',
        'event_action': 'Click',
        'event_label': 'OK'
    };
    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
});

$(document).on('login:error', 'form.login', () => {
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'login',
        'event_action': 'Click',
        'event_label': 'KO'
    };
    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
});

$(document).on('login:success', () => {
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'login',
        'event_action': 'Click',
        'event_label': 'OK'
    };
    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
});

$(document).on('login:error', () => {
    const data = {
        'event': 'gtm.custom_event',
        'event_name': 'login',
        'event_action': 'Click',
        'event_label': 'KO'
    };
    if (window.dataLayer) {
        window.dataLayer.push(data);
    }
});

/*
* Track "select_item" event on PLP
*/
$(document).on('click', '.search-results .product', (e) => {
    const pid = e.currentTarget.dataset.pid;
    const plpProducts = typeof viewData !== 'undefined' ? viewData.ecommerce.items : null;
    var filterUrl = $('.grid-footer .permalink').val();
    filterUrl = filterUrl.replace(/%25/g, '%2525');
    var scrollPosition = $(document).scrollTop();
    var cat = window.location.href.split('?')[0].split('/').pop();
    sessionStorage.setItem('scrollPosition_PLP', JSON.stringify({position: scrollPosition.toString(), cat: cat}));
    window.history.replaceState(null, "", decodeURI(filterUrl));
    if (window.dataLayer && plpProducts) {
        const gtmEvent = {
            'event': 'select_item',
            'ecommerce': {
              'items': []
            }
        };
        const productData = plpProducts.find((el) => el.item_id === pid);
        if (productData) {
            gtmEvent.ecommerce.items.push(productData);
            window.dataLayer.push(gtmEvent);

            //save cross data for product items
            var view_gtm_products = getCookie('view_gtm_products');
            var viewedListItems = view_gtm_products ? JSON.parse(atob(view_gtm_products)) : [];
            productsCrossDataManager.add({
                index: productData.index,
                item_list_name: productData.item_list_name,
                item_list_id: productData.item_list_id,
                item_id: productData.item_id,
                item_child_sku: productData.item_child_sku,
            });
        }
    }

});


/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */


/*
* Track add_to_cart event
*/
$(document).on('addToCart:success', 'body', (e, addedToCartProductEvent) => {
    if (window.dataLayer) {
        window.dataLayer.push(productsCrossDataManager.updateItemByCrossInfos(addedToCartProductEvent));
    }
});

/*    
* Track "remove_from_cart" event on cart page
*/
$(document).on('removeFromCart:success', 'body', (e, removedFromCartProductEvent) => {
    if (window.dataLayer) {
        window.dataLayer.push(productsCrossDataManager.updateItemByCrossInfos(removedFromCartProductEvent));
    }
});

/*    
* Track "plp list loaded/change"
*/
$(document).on('viewItemList:loaded', 'body', (e, viewItemListData) => {
    if (window.dataLayer) {
        window.dataLayer.push(viewItemListData);
    }
});


window.dlWaitThenPush = utils.pushToGtm;